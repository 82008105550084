<template>
  <div class="te-box rounded bg-co-gray-50">
    <slot name="content">
      <span>
        テキスト<br>
        テキスト
      </span>
    </slot>
  </div>
</template>

<style scoped>
.te-box {
  box-shadow: 0 0 8px rgba(11, 87, 112, .2);
}
</style>
